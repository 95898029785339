import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { AppConfiguration } from './app-configuration.model';

/* Declare the priva config to be able to use global var in angular */
declare let privaConfig: AppConfiguration;

@Injectable()
export class AppConfigurationService {
    private config: AppConfiguration;
    private configurationLoadedSubject: ReplaySubject<boolean>;

    constructor() {
        this.configurationLoadedSubject = new ReplaySubject<boolean>(1);
    }

    public get configuration(): AppConfiguration {
        return this.config ? this.config : privaConfig;
    }

    public loadConfiguration() {
        if (!this.config) {
            this.config = AppConfiguration.from(privaConfig);
        }

        this.configurationLoadedSubject.next(!!this.config);
    }

    /**
     * AppConfig is loaded on app start by app-config Resolver
     */
    public get configurationLoaded(): Observable<boolean> {
        return this.configurationLoadedSubject.asObservable();
    }
}
