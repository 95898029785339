import { AuthConfig } from 'angular-oauth2-oidc';

import { AppShellComponentsConfiguration } from '@priva/appshell';
import { PrivaAuthHttpOptions } from '@priva/auth/http';
import { FeatureToggleConfig } from '@priva/utilities/feature-toggles';

import { AppAnalytics } from './app-analytics.interface';
import { AppUris } from './app-uris.interface';

export class AppConfiguration {
    public static from(configuration: AppConfiguration): AppConfiguration {
        return new AppConfiguration(
            configuration.name,
            configuration.uris,
            configuration.analytics,
            configuration.features,
            configuration.components,
            configuration.showUserName,
            configuration.showTenantName,
            configuration.useLegacyRouterLinkActiveDirective,
            configuration.authentication,
        );
    }

    public constructor(
        public readonly name: string,
        public readonly uris: AppUris,
        public readonly analytics: AppAnalytics,
        public readonly features: FeatureToggleConfig,
        public readonly components: AppShellComponentsConfiguration,
        public readonly showUserName: boolean,
        public readonly showTenantName: boolean,
        public readonly useLegacyRouterLinkActiveDirective: boolean,
        public readonly authentication?: PrivaAuthHttpOptions,
        public readonly auth?: AuthConfig,
    ) {}
}
